<script setup>
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import find from 'lodash/find';
import { Validate as vValidate } from '@emobg/vue-base';
import { COUNTRIES, COUNTRIES_ISO_CODES } from '@emobg/web-utils';

const emits = defineEmits([
  'on:country-change',
]);

const props = defineProps({
  label: {
    type: String,
    default: 'Select Country',
  },
  reference: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: 'country-select',
  },
  value: {
    type: String,
    default: COUNTRIES_ISO_CODES.spain,
  },
  placeholder: {
    type: String,
    default: 'Select country',
  },
  countriesOptions: {
    type: Array,
    default: COUNTRIES.map(country => ({ value: country.code, label: country.name })),
  },
});

const onCountryChange = (countryCode) => {
  emits('on:country-change', countryCode);
};
</script>

<template>
  <div class="CountrySelect">
    <ui-select
      :ref="props.reference"
      v-validate.click="{
        isRequired: true,
      }"
      :value="get(find(countriesOptions, ['value', props.value]), 'label')"
      :name="props.name"
      :label="props.label"
      :placeholder="props.placeholder"
    >
      <div
        v-show="props.value"
        slot="selected"
        class="ml-n2 mr-0"
      >
        <div :class="['flag flag-s', `flag-${toLower(props.value)}`]" />
      </div>
      <div slot="content">
        <div
          v-for="country in countriesOptions"
          :key="country.value"
          :class="{ 'emobg-background-color-ground-light': country.value === props.value }"
          :data-test-id="`${toLower(country.value)}-option`"
          class="cursor-pointer d-flex justify-content-start align-items-center emobg-background-color-ground-lighter-hover"
          @click="onCountryChange(country.value)"
        >
          <div :class="['flag flag-s', `flag-${toLower(country.value)}`]" /> {{ country.label }}
        </div>
      </div>
    </ui-select>
  </div>
</template>
