import uniqWith from 'lodash/uniqWith';
import size from 'lodash/size';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import { ref } from 'vue';

export const useAcceptLegalDocuments = () => {
  const acceptedLegalDocuments = ref([]);

  const acceptLegalDocument = (uuids = []) => {
    if (!isArray(uuids)) {
      return;
    }

    const legalDocuments = [
      ...acceptedLegalDocuments.value,
      ...uuids,
    ];

    acceptedLegalDocuments.value = uniqWith(legalDocuments, isEqual);
  };

  const rejectLegalDocument = (rejectedDocuments = []) => {
    if (!isArray(rejectedDocuments)) {
      return;
    }

    const reject = document => !find(rejectedDocuments, rejectedDocument => isEqual(document, rejectedDocument));
    const differences = filter(acceptedLegalDocuments.value, reject);

    acceptedLegalDocuments.value = uniqWith(differences, isEqual);
  };

  const areAccepted = (documents = []) => size(acceptedLegalDocuments.value) === size(documents);

  return {
    acceptedLegalDocuments,
    acceptLegalDocument,
    rejectLegalDocument,
    areAccepted,
  };
};
