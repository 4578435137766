/* eslint-disable */
class SegmentSnippet {

  constructor() {
    window.analytics = (window.analytics = window.analytics || []);
    if (!window.analytics.initialize) {
      if (window.analytics.invoked) {
        console.error('Segment snippet included twice.');
      }else {
        this.initEmptyAnalytics();
        this.initFactory();
        this.initMethods();
      }
    }
  }

  create(key, version) {
    const self = this;
    this.instance = new Promise(function(resolve){
      self.load(key, resolve);
    });
    window.analytics.SNIPPET_VERSION = version;
    window.analytics.page();
  }

  initEmptyAnalytics() {
    window.analytics.invoked = !0;
    window.analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
    ];
  }

  initFactory() {
    window.analytics.factory = function (t) {
      return function() {
        var e = Array.prototype.slice.call(arguments);
        e.unshift(t);
        window.analytics.push(e);
        return window.analytics;
      };
    }
  }

  initMethods() {
    for (var t = 0; t < window.analytics.methods.length; t++) {
      var e = window.analytics.methods[t];
      window.analytics[e] = window.analytics.factory(e);
    }
  }

  load(t, resolve) {
    var n = document.createElement('script');
    n.type = 'text/javascript';
    n.async = !0;
    n.onload = () => {
      resolve(window.analytics);
    };
    n.src =
      ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'cdn.segment.com/analytics.js/v1/' +
      t +
      '/analytics.min.js';
    var o = document.getElementsByTagName('script')[0];
    o.parentNode.insertBefore(n, o);
  }

  getInstance() {
    return this.instance;
  }
}

const SegmentInstance = new SegmentSnippet();

export default SegmentInstance;
