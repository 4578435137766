export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w!@#$%^&*]{8,64}$/;

/**
 * Valid characters:
 * alphabetic: a-z, A-Z
 * numeric: 0-9
 * special characters: ~@#$%^&*+=`|{}:;!.?"()[]-
 *
 * Required:
 * minimum one number
 * minimum one uppercase letter
 * minimum eight characters
 */
